import axios from "axios";
import { config } from "./config";

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

axiosInstance.interceptors.request.use(
  function (config) {
    let data = config.data ? config.data : {};
    let params = config.params ? config.params : {};

    const token = localStorage.getItem("authToken");

    if (token) {
      data["token"] = token;

      if (config.method === "get") {
        params["token"] = token;
      }
    }

    config.data = data;
    config.params = params;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
